<script>
import config from "../config";
import {IonButton} from "@ionic/vue";

export default {
  name: "PaidComponent",
  data() {
    return {
      isPaymentSuccessfully: true,
    }
  },
  components: {
    IonButton
  },
  mounted() {
    let _this = this;
    let user = this.$api.auth;
    if (user?.subscription_status === 'UNPAID') {
      this.isPaymentSuccessfully = false;
    }
    this.$emitter.on('updateProfile', (profileData) => {
      if (profileData?.subscription_status === 'UNPAID') {
        _this.isPaymentSuccessfully = false;
      } else {
        _this.isPaymentSuccessfully = true;
      }
    });
  },
  methods: {
    retrySubscription: async function() {
      let content = await this.$api.post(
          "retry-vip"
      );
      console.log(content);
    },
    reactivateSubscription: async function() {
      let content = await this.$api.post(
          "reactivate-vip"
      );
      this.formUrl = config.api.url + 'set-vip-content?user_id=' + global.api.auth.id + '&key=' + content.data.key + '&user_hash=' + content.data.user_hash;
      this.$emitter.emit('vipSet',this.formUrl)
    },
    deleteSubscription: function () {
      let del = false;
      return this.$helper.setAlert('Aboneliği kapatmak istediğinizden Emin Misiniz?', '', '',
          [{
            text: 'Evet', handler: () => {
              del = true;
            }
          }, {text: 'Hayır'}]
      ).then(async alert => {
        let _this = this;
        await alert.present();
        await alert.onDidDismiss();
        if (del) {
          this.$api.post('/delete-vip')
              .then((e) => {
                if (e.status !== 404) {
                  _this.$helper.setToast('Abonelik durumu güncellendi!', 'dark').then(toast => toast.present());
                  _this.$emitter.emit('updateProfile', _this.$api.getAuth());
                }
              })
              .catch((e) => {
                const message = this.helper.setErrorMessage(e.response);
                this.helper.setAlert('Hata', '', message).then(alert => alert.present());
                return e.response;
              });
        }
      });
    },
  }
}
</script>

<template>

  <template v-if="!isPaymentSuccessfully">
    <div class="landing-banner" style="background-color: #de273d; padding: 0">
      <div class="container">
        <div class="flex-inline" style="align-content: flex-end;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;">
          <p style="color: white; margin-bottom: 0; margin-right: 20px;">Abonelik Yenileme Hatası</p>
          <ion-button @click="retrySubscription" color="success">
            Yeniden Ödeme Almayı Dene
          </ion-button>
          <ion-button @click="reactivateSubscription" color="success">
            Farklı Bir Kart İle Dene
          </ion-button>
          <ion-button @click="deleteSubscription" color="danger">
            Abonelik Silme
          </ion-button>
        </div><!-- .lb-info -->
      </div>
    </div>

  </template>
</template>

<style scoped>

</style>