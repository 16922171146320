<template>
  <ion-page>
    <ion-content>
      <paid-component/>
      <div class="tab-buttons container">
        <div class="row" style="background-color: white;display: none;">
          <div class="col-6">
            <ion-button @click="changeTab('message')"
                        :color="selectedTab === 'message' ? 'dark': 'light'"
                        :class="{ 'tab-button-active': selectedTab === 'message' }" style="width: 100%;">
              <ion-icon :icon="selectedTab === 'message' ? mailIcon() : mailOutline()"
                        color="success">
              </ion-icon>
              <ion-label>Mesajlar</ion-label>
            </ion-button>
          </div>
          <div class="col-6">
            <ion-button @click="changeTab('settings')" :class="{ 'tab-button-active': selectedTab === 'settings' }"
                        :color="selectedTab === 'settings' ? 'dark': 'light'"
                        style="width: 100%;">
              <ion-icon :icon="selectedTab === 'settings' ? settings() : settingsOutline()" color="success"></ion-icon>
              <ion-label>Ayarlar</ion-label>
            </ion-button>
          </div>
        </div>
      </div>
      <main id="main" class="sitemain">
        <div class="page-title page-title--small align-left" style="background-color: #53bd6c!important;">
          <div class="container">
            <div class="page-title__content">
              <h1 class="page-title__name">Mesajlarınız</h1>
              <p class="page-title__slogan">Burada mesajlarınız görürsünüz.</p>
            </div>
          </div>
        </div>
      </main>
      <div>
        <Transition mode="out-in">
          <div v-if="selectedTab === 'messages'" class="site-content">
            <div class="checkout-area">
              <div class="container">
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-12 col-lg-6">
                    <div class="billing-form" style="    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;">
                      <div>
                        <table style="width: 100%;">
                          <thead>
                          </thead>
                          <tbody>
                          <tr v-for="(thread, index) in messages" :key="index" @click="showMessage(thread)" style="cursor: pointer;">
                            <td :style="thread.isUnread === false ? '' : 'color: green; font-weight:bolder'">
                             <div>
                               Başlık : {{ thread.subject }}
                             </div>
                              <div>
                                {{ latestMessageUser(thread.latestMessage,thread) }} : {{ thread.latestMessage.body }}
                              </div>
                            </td>
                            <td>
                              <ion-button  class="white" color="light" shape="round" size="small" style="--padding-start: 0; --padding-end: 0;">
                                <ion-icon slot="icon-only" :icon="eyeOutline()" color="dark"></ion-icon>
                              </ion-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                      <ion-button @click="loadMessages()" class="white" color="light" shape="round"
                                  :disabled="nothingLeft">
                        {{ nothingLeft ? 'En alta indin' : 'Daha fazla' }}
                        <ion-icon slot="icon-only" :icon="eyeOutline()" color="dark"></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="site-content">
            <div class="checkout-area">
              <div class="container">
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-12 col-lg-6">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="billing-form">
                          <h2>Mesaj Tercihleri</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <ion-modal :isOpen="isOpen" :backdrop-dismiss="false">
        <MessageModal :thread="selectedThread"/>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import {defineComponent} from 'vue';
import {
  IonPage,
  IonButton,
  IonIcon,
  IonLabel, IonContent, IonModal
} from "@ionic/vue";
import {
  createOutline,
  eyeOutline, mail, mailOutline,
  settings,
  settingsOutline,
  trashOutline
} from "ionicons/icons";
import MessageModal from "../components/MessageModal.vue";
import {ref} from "@vue/runtime-core";
import moment from "moment";
import PaidComponent from "../components/PaidComponent.vue";

export default defineComponent({
  name: 'Message',
  components: {
    PaidComponent,
    IonIcon,
    IonPage, IonButton, IonLabel,
    IonContent,
    MessageModal,
    IonModal,
  },
  methods: {
    isRead(message) {
      return !message.isUnread;
    },
    latestMessageUser(message, thread){
      if (message.user_id === this.$api.auth.id){
        return 'Siz';
      }
      return thread.participants.filter(participant=> parseInt(message.user_id)===parseInt(participant.user.id))[0].user.name;
    },
    settings() {
      return settings
    },
    changeTab(tab) {
      this.selectedTab = tab;
    },
    settingsOutline() {
      return settingsOutline
    },
    mailIcon() {
      return mail
    },
    mailOutline() {
      return mailOutline
    },
    trashOutline() {
      return trashOutline
    },
    eyeOutline() {
      return eyeOutline
    },
    createOutline() {
      return createOutline
    },
    loadMessages: function () {
      let _this = this;
      this.$api.Model.custom('messages').page(this.pageNumber++).get().then(
          function (response) {
            response.links.next === null ? _this.nothingLeft = true : _this.nothingLeft = false;
            _this.messages = _this.messages.concat(response.data);
          }
      );
    },
    showMessage: function (message) {
      this.selectedThread = message;
      this.isOpen = true;
      message.isUnread = false;
    }
  },
  setup() {
    return {
      messages: ref([])
    };
  },
  mounted() {
    let _this = this;
    this.$emitter.on('closeMessageModal', () => {
      _this.isOpen = false;
    });
    this.$emitter.on('gotMessage', ({thread, message}) => {
      let messageThread = _this.messages.filter(threadData=> parseInt(threadData.id) === parseInt(thread.id))[0];
      messageThread.latestMessage = message;
      messageThread.isUnread = thread.isUnread;
      messageThread.updated_at = thread.updated_at;
      _this.messages.sort(function(x, y){
        return moment(x.updated_at).valueOf() - moment(y.updated_at).valueOf();
      })
    });
    this.loadMessages();
  },
  data() {
    return {
      isOpen: false,
      selectedTab: 'messages',
      selectedThread: null,
      nothingLeft: true,
      pageNumber: 1,
    }
  }


});

</script>

<style scoped>
.red {
  color: red;
}

.green {
  color: green;
}

.tab-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: unset;
  z-index: 999;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
