<template>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ thread.subject }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="dismissModal()">Kapat</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :scrollY="false">
      <div class="site-content" style="position:relative;height: 100%" @scroll="onScroll">
        <div class="container imessage" style="height:100%;overflow-y: scroll;flex-direction: column-reverse"
             @scroll="onScroll" ref="scrollContainer">

          <template v-for="message in messages" :key="message.id">
            <div v-if="message.user_id === $api.auth.id" class="from-me">
              {{ message.body }}
              <div style="font-size: 10px;max-width: unset;padding: 0.2rem 0;display: flex;">
                <div style="font-size: 10px;max-width: unset;padding: 0;align-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;">
                  <div style="font-size: 10px;max-width: unset;padding: 0;">
                    {{ thread.participants.filter(user => user.id === message.user_id)[0].user.name }}
                  </div>
                  <div style="font-size: 10px;max-width: unset;padding: 0;">{{ showTime(message.createdTS) }}</div>
                </div>
                <img :src="$api.auth.avatar" style="width: 24px;margin-left: 10px;"/>
              </div>
            </div>
            <div v-else class="from-them">

              {{ message.body }}
              <div style="font-size: 10px;max-width: unset;padding: 0.2rem 0;display: flex;">
                <img
                    :src="thread.participants.filter(user => user.id === message.user_id)[0].user.avatar"
                    style="width: 24px;margin-right: 10px;"/>
                <div style="font-size: 10px;max-width: unset;padding: 0;">
                  <div style="font-size: 10px;max-width: unset;padding: 0;">
                    {{ thread.participants.filter(user => user.id === message.user_id)[0].user.name }}
                  </div>
                  <div style="font-size: 10px;max-width: unset;padding: 0;">{{ showTime(message.createdTS) }}</div>
                </div>
              </div>
            </div>
          </template>
          <div v-if="loading || nothingLeft" style="margin: 0 auto">
            <ion-spinner v-if="loading" name="dots"/>
            <p v-if="nothingLeft">
              Konuşma Başı
            </p>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer style="position: fixed;
    z-index: 100000000000000000;
    bottom: 0;">

      <form @submit="sendForm" style="border-top: 1px black;">
        <div class="row" style="margin: 10px 10px;">
          <ion-item style="width: 100%;padding: 0!important;align-items: center;
    align-self: stretch;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;">
            <ion-label for="title" position="floating">
              Mesajınız Yazın...
              <span class="required">*</span>
            </ion-label>
            <ion-input required name="message" v-model="messageSend"></ion-input>

            <ion-button type="submit" slot="end" style="width: 20%;height: 80%; margin: 0;">
              <ion-icon :icon="sendOutline()">
              </ion-icon>
            </ion-button>
          </ion-item>
        </div>
      </form>
    </ion-footer>
</template>

<script>
import {
  IonButton, IonButtons,
  IonContent, IonFooter,
  IonHeader, IonIcon,
  IonInput, IonItem,
  IonLabel, IonSpinner,
  IonTitle,
  IonToolbar
} from '@ionic/vue';
import moment from 'moment'
import {defineComponent} from 'vue';
import {sendOutline} from "ionicons/icons";

export default defineComponent({
  name: 'SearchNotificationModal',
  props: {
    isOpen: {
      type: Boolean
    },
    thread: {
      type: Object
    },
    backdropDismiss: {
      type: Boolean,
    }
  },
  data() {
    return {
      messages: [],
      messageSend: '',
      pageNumber: 1,
      loading: false,
      nothingLeft: false,
    }
  },
  mounted() {
    let _this = this;
    this.$emitter.on('gotMessage', function ({message, thread}) {
      if (parseInt(_this.thread.id) === parseInt(thread.id)) {
        _this.messages = [message, ..._this.messages];
        setTimeout(() =>
            _this.$refs?.scrollContainer?.scrollTo(0, _this.$refs?.scrollContainer?.scrollHeight), 100)
      }
    })
    this.loadMessages();
    this.$emitter.emit('updateProfile', _this.$api.getAuth());
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonItem,
    IonInput,
    IonButton,
    IonButtons,
    IonFooter,
    IonIcon,
    IonSpinner,
  },
  methods: {
    showTime(timestamp) {
      moment.locale('tr');
      return moment.unix(parseInt(timestamp)).fromNow()
    },
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if ((-1 * scrollTop) + clientHeight >= scrollHeight - 10) {
        let _this = this;
        this.loading = true;
        this.loadMessages().then(function () {
          _this.loading = false;
        });
      }
    },
    sendOutline() {
      return sendOutline
    },
    getOption: function (optionName) {
      let val = Object.keys(this.searchNotification.search_notification_options).filter((key) =>
          this.searchNotification.search_notification_options[key]?.type === optionName);
      return this.searchNotification.search_notification_options[val];
    },
    dismissModal: function () {
      this.$emitter.emit('closeMessageModal');
    },
    loadMessages: async function () {
      if (this.nothingLeft === false) {
        let response = await this.$api.Model.custom('messages/' + this.thread.id).page(this.pageNumber++).get();
        response.links.next === null ? this.nothingLeft = true : this.nothingLeft = false;
        this.messages = this.messages.concat(response.data);
      }
    },
    sendForm: function (event) {
      let _this = this;
      const formData = new FormData(event.target);
      event.preventDefault();
      this.$api.put('/messages/' + this.thread.id, formData)
          .then(function (res) {
            if (res.status === 200) {
              _this.messageSend = '';
              _this.$helper.setToast('Mesaj Gönderildi', 'dark', 2000, true, {
                position: 'top',
                color: 'success',
              }).then(toast => toast.present());
              _this.$emitter.emit('gotMessage', {thread: _this.thread, message: res.data.data.latestMessage});
            }
          });
    }
  }
});

</script>
<style>
.imessage {
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  position: absolute;
  bottom: 9rem;
  padding: 9rem 1.5rem 0.5rem;
  align-content: space-around;
  flex-wrap: nowrap;
  overflow-wrap: break-word;
}

.imessage div {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem .875rem;
  position: relative;
  word-wrap: break-word;
  width: fit-content;
}

.imessage div::before,
.imessage div::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

.from-me {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff;
  margin-top: 0.25rem;
}

.from-them:first-child, .from-me:first-child {
  margin-top: 0.25rem;
}

.from-them:last-child, .from-me:last-child {
  margin-top: 0.25rem;
}

.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.from-them {
  margin-top: 0.25rem;
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
}

.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #e5e5ea;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

.from-them::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}
</style>